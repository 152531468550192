<template>
    <div class="container">


        <h2 class="head2 mb-5">{{Lang.get('link8')}}</h2>
        <div class="text-red bold Truculenta text-37" style="max-width:850px;margin:auto;" v-html="Lang.get('text8')"></div>
        <div class="mt-5 mb-5">

            <img src="../assets/operasyonel-mukemmellik.png" class="img-fluid" />
        </div>
        <div v-if="Lang.SelectedLanguage=='tr'">

            <div style="max-width:570px;margin:auto;">
                <p>
                    Operasyonel mükemmellik çalışmaları temel olarak üç ana odakta ilerletilmektedir; “Sıfır İş Kazası”, “Sıfır Kalite Problemi” ve “Sıfır Kayıp”. İyileştirme çalışmalarının sayısı her yıl artmaktadır. Bugüne dek toplam 2 bine yakın iyileştirme çalışması tamamlanarak raporlanmıştır. Bu çalışmalarla 2018 yılında 16,5 milyon TL, 2019 yılında 10 milyon TL tasarruf sağlanmıştır. 2020 yılı boyunca elde edilen tasarruf miktarı ise 21 milyon TL seviyesinde gerçekleşmiştir. Bu tasarruflar, yatırım yapılmaksızın ve minimum harcama yapılarak gerçekleştirilen iyileştirmeleri kapsamaktadır.

                </p>
                <p>
                    Operasyonel mükemmellik çalışmaları, Ülker fabrikalarında gönüllülük esasıyla oluşturulmuş ekipler tarafından yürütülmektedir. Bununla birlikte, fabrika ekiplerinin tüm eğitim, danışmanlık ve koçluk ihtiyaçları üç kişiden oluşan Operasyonel Mükemmellik (OM) Merkez Ekibi tarafından karşılanmaktadır.

                </p>
            </div>
        </div>
        <div v-if="Lang.SelectedLanguage=='en'">
            <div style="max-width:570px;margin:auto;">
                <p>
                    Ülker has created its own “operational excellence
                    model,” inspired by the internationally proven
                    approaches including Lean Manufacturing, Total
                    Productive Maintenance, Total Quality Management,
                    Six Sigma and 5S. Called the Yıldız Path of Excellence
                    (YMY), the program is based on the principle of
                    “achieving sustainable results with happy and
                    motivated teams that implement perfect systems.”
                    Work follow-up is carried out through committees
                    formed on a voluntary basis. These committees,
                    which operate in different fields, include employees
                    from various departments and positions. To
                    help factories to reach their main targets, each
                    committee continues to work in alignment with its
                    own road map.

                </p>
                <p>
                    From operators to senior management, each
                    employee is active in operational excellence
                    processes, utilizing improvement tools from all levels.
                    Each factory creates its strategic plan and sets goals
                    under the name of “Zero Loss Journey,” and all
                    improvement activities are managed in line with this
                    plan.
                    Operational excellence studies are basically
                    advanced in three main focuses: “Zero Work
                    Accidents,” “Zero Quality Problems” and “Zero Loss.”
                    Number of improvement works is increasing each
                    year., although the number increases each year.
                    A total of nearly 2 thousand improvements are
                    completed and reported up to the present. With
                    these efforts, approximately TL 16.5 million was
                    saved in 2018 and TL 10 million in 2019. The amount
                    of savings achieved during 2020 was TL 21 million.
                    These savings comprise the improvements realized
                    without making any investment and with minimal
                    expenditure.
                    Operational excellence studies are carried out
                    by teams created on a voluntary basis at Ülker
                    Factories. However, all the training, consultancy
                    and coaching needs of the factory teams are met
                    by the Operational Excellence (OM) Center Team,
                    consisting of three people.
                </p>
            </div>

        </div>
        <router-link :to="'/'+Lang.SelectedLanguage" class="backhome"> {{Lang.get('backhome')}} </router-link>
        <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/Operasyonel Mükemmellik.pdf" class="download">{{Lang.get('download')}}</a>
        <a v-if="Lang.SelectedLanguage=='en'" target="_blank" href="/files/Operational Excellence.pdf" class="download">{{Lang.get('download')}}</a>
    </div>




</template>

<script>
    import lng from "../store/languages";

    export default {
        name: 'Chairman',
        components: {},
        data() {
            return {
                Lang: lng,
            }
        }
    }</script>
